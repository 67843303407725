import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {MdHome, MdMessage, MdNotifications, MdNotificationsActive, MdSettings} from 'react-icons/md';
import Content from "../components/Content";
import Messages from "../components/Message";
import Notifications from "../components/Notifications";
import Settings from "../components/Settings";
import {BACKEND_URL} from '../const';

const Home = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [view, setView] = useState('home');
  const [likeableUser, setLikeableUser] = useState(null);

  const fetchHome = async () => {
    // event.preventDefault();
    try {
      const userResponse = await axios(`${BACKEND_URL}/auth`);
      if (userResponse.data?.status === "OK") {
        setUser(userResponse.data?.data?.auth?.user);
        console.log(userResponse.data?.data?.auth?.user);
        if (userResponse.data?.data?.auth?.user?.first_step) {
          navigate('/firstStep');
        }
      } else {
        throw new Error("Invalid userResponse");
      }
      // todo megcsinalni
      const likeableResponse = await axios(`${BACKEND_URL}/likeableUser`);
      if (likeableResponse.data?.status === "OK") {
        console.log(likeableResponse.data?.data?.likeableUser);
        setLikeableUser(likeableResponse.data?.data?.likeableUser?.user);
      } else {
        throw new Error("Invalid likeableResponse");
      }

    } catch (error) {
      localStorage.removeItem('token');
      console.error('Failed to fetchHome user:', error);
      navigate('/');
    }
  };

  useEffect(() => {
    fetchHome();
  }, []);

  const renderView = () => {
    switch (view) {
      case "messages":
        return <Messages/>;
      case "notifications":
        return <Notifications user={user}/>;
      case "settings":
        return <Settings user={user}/>;
      default:
        return <Content likeableUser={likeableUser} onLikeableUserUpdate={fetchHome}/>;
    }
  }

//todo szedegetni a komment reszbol
  if (user === null) {
    return <div className="flex justify-center items-center min-h-screen dark:text-white">Loading...</div>;
  } else {
    // if (!likeableUser) {
    // } else {
    return (
        <div className={"flex justify-center min-h-screen"}>
          <div
              className={"max-w-xl w-full mx-auto flex flex-col justify-between rounded h-screen"}>
            <div
                className={"fixed top-0 left-0 right-0 flex justify-center p-2"}>
              <div className="max-w-md w-full flex justify-around items-center">
                <div className={"p-4 bg-cyan-200 rounded-full shadow-lg"} onClick={() => setView('home')}>
                  <MdHome size={32}/>
                </div>
                <div className={"p-4 bg-cyan-200  rounded-full shadow-lg"} onClick={() => setView('messages')}>
                  <MdMessage size={32}/>
                </div>
                <div className={"p-4 bg-cyan-200  rounded-full shadow-lg"} onClick={() => setView('notifications')}>
                  <MdNotifications size={32}/>
                  {/*<MdNotificationsActive/> todo adatbázisban meg kell csinálni a notifikációt*/}
                </div>
                <div className={"p-4 bg-cyan-200  rounded-full shadow-lg"} onClick={() => setView('settings')}>
                  <MdSettings size={32}/>
                </div>
              </div>
            </div>
            {renderView()}
          </div>
        </div>
    );
  }
  // }
};

export default Home;