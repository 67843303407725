import React, {useEffect, useState} from "react";
import {FcLike, FcDislike} from 'react-icons/fc';
import axios from "axios";
import {BACKEND_URL} from "../const";

const Likes = ({likeableUser, onLikeableUserUpdate}) => {
  const [likes, setLikes] = useState(null);

  useEffect(() => {
    const fetchLikes = async () => {
      try {
        const response = await axios.post(`${BACKEND_URL}/like`, {userId: likeableUser.id, like: likes});
        if (response.data?.status === "OK") {
          //todo ide kellene egy megoldas, hogy ne az oldalt frissitse hanem a home komponensben fusson le a fetchLikes(); fuggveny
          // window.location.reload();
          setLikes(null);
          onLikeableUserUpdate();
        } else {
          throw new Error("Failed to fetchLikes likes");
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (likes !== null) fetchLikes();
  }, [likeableUser.id, likes, onLikeableUserUpdate]);

  return (
      <div className={"fixed bottom-0 left-0 right-0 z-50 flex justify-center p-4"}>
        <div className="max-w-md w-full flex justify-around items-center">
          <div className={"text-red-800 bg-red-200 rounded-full p-4 flex justify-center items-center shadow m-4"}
               onClick={() => setLikes(0)}>
            <FcDislike size={32}/>
          </div>
          <div className={"text-green-800 bg-green-200 rounded-full p-4 flex justify-center items-center shadow m-4"}
               onClick={() => setLikes(1)}>
            <FcLike size={32}/>
          </div>
        </div>
      </div>
  );
}

export default Likes;
