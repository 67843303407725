import React, {useEffect, useState} from "react";
import axios from "axios";
import {BACKEND_URL} from "../const";

const Notifications = ({user}) => {
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {

    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/notifications`);
        if (response.data?.status === "OK") {
          console.log(response.data?.data?.notifications);
          setNotifications(response.data?.data?.notifications);
        } else {
          throw new Error("Invalid response");
        }
      } catch (error) {
        console.error('Failed to fetch user:', error);
        setError(true);
      }
    }
    if (user) fetchNotifications();
  }, [user]);

  if (error) {
    return (
        <div className="flex justify-center items-center h-screen">
          <div className="text-center">
            <h2 className="text-3xl">Hiba történt az üzenetek betöltése közben.</h2>
            <p>{error}</p>
          </div>
        </div>
    );
  }

  if (notifications.length === 0) {
    return (
        <div className="flex justify-center items-center h-screen">
          <div className="text-center">
            <h2 className="text-3xl">Nincs értesítésed!</h2>
          </div>
        </div>
    );
  } else {
    return (
        <div className={"mt-20 mb-20 overflow-auto"}>
          {notifications.map((notification, index) => (
              <div key={index} className="p-3.5 mb-4 bubble shadow-lg rounded-2xl text-justify">
                <p className="font-bold">
                  {notification.type === 'message' ? `Üzenet érkezett ${notification.user?.name}-tól/től` :
                      notification.type === 'like' ? `Te és ${notification.user?.name} egymásra találtatok!` : ''}
                </p>
                <div className="text-lg text-right">
                  {notification.updated_at}
                </div>
              </div>
          ))}
        </div>
    );
  }
}

export default Notifications;