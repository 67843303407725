import {useParams} from "react-router-dom";
import {useEffect} from "react";
import axios from "axios";
import {BACKEND_URL} from "../const";
import {Response} from "../class/Response";
import {Status} from "../Enum/Status";

const Chat = () => {
  const {id} = useParams();

  useEffect(() => {
    const fetchChat = async () => {
      try {
        console.log(id);
        const response = await axios.post(`${BACKEND_URL}/relmsg`,
            {msg_to: id});
        const resp = new Response(response);
        if (resp.getStatus() === Status.OK) {
          console.log(resp.getData());
        } else {
          throw new Error("Invalid response");
        }
      } catch (error) {
        console.error('Failed to fetch chat:', error);
      }
    }
    fetchChat();
  }, [id]);

  return (
      <div>
        Chat
      </div>
  );
}

export default Chat;