import React, {useEffect, useState} from "react";
import axios from "axios";
import {BACKEND_URL} from "../const";
import {Status} from "../Enum/Status";
import {Response} from "../class/Response";
import {useNavigate} from "react-router-dom";


const Message = () => {
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingDelay, setLoadingDelay] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMsg = async () => {
      const timeOut = setTimeout(() => setLoadingDelay(true), 100);
      try {

        const response = await axios.get(`${BACKEND_URL}/relmsg`);
        // const timeoutPromise = new Promise(resolve => setTimeout(resolve, 100));
        // const response = await Promise.all([responsePromise, timeoutPromise]);
        const resp = new Response(response);
        if (resp.getStatus() === Status.OK) {
          setMessages(resp.getData().messages);
          setLoading(false);
        } else {
          throw new Error("Invalid response");
        }
      } catch (error) {
        console.error('Failed to fetch msg:', error);
        setError(true);
      } finally {
        clearTimeout(timeOut);
      }
    }
    fetchMsg();
  }, []);


  if (loading && !loadingDelay) {
    return null;
  }

  if (loading) {
    return (
        <div className="flex justify-center items-center h-screen">
          <div className="text-center">
            <div className={'spinner'}></div>
          </div>
        </div>
    );
  }
  if (error) {
    return (
        <div className="flex justify-center items-center h-screen">
          <div className="text-center">
            <p className="text-3xl">Hiba történt az üzenetek betöltése közben.</p>
          </div>
        </div>
    );
  }

  if (messages.length === 0) {
    return (
        <div className="flex justify-center items-center h-screen">
          <div className="text-center">
            <h2 className="text-3xl">Nincs üzeneted!</h2>
          </div>
        </div>
    );
  } else {
    return (
        <div className={"mt-20 mb-20 overflow-auto"}>
          {messages.map((message, index) => (
              <div key={index} className="p-3.5 mb-4 bubble shadow-lg rounded-2xl text-justify"
                   onClick={() => navigate(`/chat/${message.user?.id}`)}>
                <p className="font-bold">
                  {message.user?.name}
                </p>
                <div className="text-lg text-right">
                  {message.last_msg}
                </div>
              </div>
          ))}
        </div>
    );
  }
}

export default Message;