// On page load or when changing themes, best to add inline in `head` to avoid FOUC

document.body.classList.toggle('dark',localStorage.getItem('theme') === 'dark');

if ((localStorage.getItem('theme')===null) && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  document.body.classList.add('dark')
}

// if (localStorage.getItem('theme') === 'dark') {
//   document.body.classList.add('dark');
// } else if (localStorage.getItem('theme') === 'light') {
//   document.body.classList.remove('dark')
// } else if ((!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
//   document.body.classList.add('dark')
//   localStorage.setItem('theme', 'dark');
// }else {
//   document.body.classList.remove('dark')
//   localStorage.setItem('theme', 'light');
// }

//
// // Whenever the user explicitly chooses light mode
// localStorage.theme = 'light'
//
// // Whenever the user explicitly chooses dark mode
// localStorage.theme = 'dark'
//
// // Whenever the user explicitly chooses to respect the OS preference
// localStorage.removeItem('theme')