import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import {BACKEND_URL} from '../const';
import {useNavigate} from "react-router-dom";
//todo kell minden loginból + 201-el tér vissza nem 200
const RegisterForm = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async event => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage(t('passwordMismatch'));
      return;
    }

    try {
      const response = await axios.post(`${BACKEND_URL}/register`, {email, password});
      if (response.status === 201) { //todo 201 és firstTime oldalra kell majd vinni
        // itt kezeld a sikeres regisztrációt, például navigálj a bejelentkezési oldalra
        console.log('Registration successful');
        if (response.data?.status === "OK" && response.data?.data) {
          const token = response.data.data;
          localStorage.setItem('token', token);
          navigate('/firstStep');
        }
        // navigate('/firstStep');
        // window.location.href = '/login';
      } else {
        setErrorMessage(t('registrationError'));
      }
    } catch (error) {
      setErrorMessage(t('serverError'));
    }
  };

  return ( //todo loginból hogy ne legyen annyira magasan + a hibaüzenetet kiírni
      <form className="flex flex-col mt-4" onSubmit={handleSubmit}>
        {errorMessage && <p className="mb-2 text-red-500">{errorMessage}</p>}
        <input
            className="mb-2 p-2 bg-cyan-50  rounded-2xl shadow-lg"
            type="email"
            placeholder={t('emailPlaceholder')}
            value={email}
            autoFocus={true}
            required={true}
            onChange={e => setEmail(e.target.value)}
        />
        <input
            className="mb-2 p-2 bg-cyan-50 rounded-2xl shadow-lg"
            type="password"
            placeholder={t('passwordPlaceholder')}
            value={password}
            required={true}
            onChange={e => setPassword(e.target.value)}
        />
        <input
            className="mb-2 p-2 bg-cyan-50 rounded-2xl shadow-lg"
            type="password"
            placeholder={t('reenterPasswordPlaceholder')}
            value={confirmPassword}
            required={true}
            onChange={e => setConfirmPassword(e.target.value)}
        />
        <button
            className="p-2 bg-cyan-500 hover:bg-cyan-700 text-white rounded-2xl shadow-lg">{t('registerBtn')}</button>
      </form>
  );
}

export default RegisterForm;
