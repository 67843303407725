import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import LoginForm from '../components/LoginForm';
import RegisterForm from '../components/RegisterForm';
import {FaMoon, FaSun} from 'react-icons/fa';
import {IoLanguage} from 'react-icons/io5';
import i18n from "i18next";
import {useNavigate} from 'react-router-dom';

const Welcome = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/home');
    }
  }, [navigate]);

  const [isLogin, setIsLogin] = useState(true);
  const {t} = useTranslation();
  const [darkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'dark');
  const [language, setLanguage] = useState(i18n.language);


  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  }

  useEffect(() => {
    document.body.classList.toggle('dark', darkMode);
    localStorage.setItem('theme', darkMode ? 'dark' : 'light');

  }, [darkMode]);

  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'hu' : 'en';
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  }

  return (
      <div className="flex justify-center items-center min-h-screen relative">
        <div className="absolute top-4 right-4 dark:text-white">
          <IoLanguage className="inline mr-4 cursor-pointer" onClick={toggleLanguage}/>
          {darkMode ? (
              <FaSun className="inline cursor-pointer" onClick={toggleDarkMode}/>
          ) : (
              <FaMoon className="inline cursor-pointer" onClick={toggleDarkMode}/>
          )}
        </div>
        <div className="p-6 bg-cyan-100 rounded-2xl shadow-lg max-w-xs w-full flex flex-col items-center z-10">
          <h2 className="text-xl mb-4 text-center">{isLogin ? t('signIn') : t('register')}</h2>
          {isLogin && <LoginForm/>}
          {!isLogin && <RegisterForm/>}
          <div className={"mt-4"}>
            {isLogin ? (
                <p>
                  {t('notAMember')}{" "}
                  <br/>
                  <span
                      className="underline cursor-pointer text-teal-900"
                      onClick={() => setIsLogin(false)}
                  >
                                {t('registerNow')}
                            </span>
                </p>
            ) : (
                <p>
                  {t('alreadyMember')}{" "}
                  <br/>
                  <span
                      className="underline cursor-pointer text-teal-900"
                      onClick={() => setIsLogin(true)}
                  >
                                {t('signInNow')}
                            </span>
                </p>
            )}
          </div>
        </div>
      </div>
  );
}

export default Welcome;
