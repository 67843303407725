import React from 'react';
import Welcome from "./page/Welcome";
import './i18n';
import {BrowserRouter as Router, Routes, Route, useNavigate} from 'react-router-dom';
import Home from "./page/Home";
import FirstStep from "./page/FirstStep";
import axios from "axios";
import Chat from "./page/Chat";
// import {ErrorProvider} from "./services/ErrorContext";

const App = () => {
  // axios.defaults.headers.common['Auth'] = localStorage?.getItem('token');
  axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Auth'] = token;
    }
    return config;
  }, error => {
    return Promise.reject(error);
  });

  return (
      <Router>
        {/*<ErrorProvider>*/}
        <Routes>
          <Route path="/" element={<Welcome/>}/>
          <Route path="/home" element={<Home/>}/>
          <Route path="/firstStep" element={<FirstStep/>}/>
          <Route path="/chat/:id" element={<Chat/>}/>
          <Route path="*" element={<Home/>}/>
        </Routes>
        {/*</ErrorProvider>*/}
      </Router>
  );
}

export default App;
