import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./language/en.json";
import hu from "./language/hu.json";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en
            },
            hu: {
                translation: hu
            }
        },
        lng: "hu", // alapértelmezett nyelv
        fallbackLng: "en", // amennyiben a kiválasztott nyelv nem elérhető, ezt használja
        interpolation: {
            escapeValue: false
        }
    });

