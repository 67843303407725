import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./App";
import './dark'
import * as serviceWorker from "./serviceWorker";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <div className={'text-black bg-cyan-50 min-h-screen text-lg dark:bg-cyan-950'}>
        <App/>
      </div>
    </React.StrictMode>
);

serviceWorker.register();