import React from 'react';
import Likes from "./Likes";
import Profile from "./Profile";

const Content = ({likeableUser, onLikeableUserUpdate}) => {

  if (!likeableUser) {
    return (
        <div className="flex justify-center items-center h-screen">
          <div className="text-center">
            <h2 className="text-3xl">Úgy tűnik, mindenki elfogyott!</h2>
            <p className="text-2xl">Frissítsd az oldalt vagy próbáld meg később!</p>
          </div>
        </div>
    );
  } else {
    return (
        <div className="mt-20 mb-20 overflow-auto">
          <Profile likeableUser={likeableUser}/>
          <Likes likeableUser={likeableUser} onLikeableUserUpdate={onLikeableUserUpdate}/>
        </div>
    );
  }

};

export default Content;
