export class Response {
  constructor(response) {
    this.data = response.data?.data;
    this.status = response.data?.status;
  }

  getData() {
    return this.data;
  }

  getStatus() {
    return this.status;
  }
}